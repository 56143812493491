import { Header } from 'src/components/Header/Header';

import UserForm from '../components/UserForm/Form';
import Footer from '../components/Footer/Footer';

const UserInformation = () => {
  return (
    <div className='flex flex-col w-full bg-white items-center justify-center gap-2'>
      <Header />
      <div className='w-full md:w-[500px] p-10  flex flex-col space-y-5 h-full '>
        <h1 className='md:text-3xl text-xl font-poppins font-semibold '>
          Personal Information
        </h1>
        <p className='text-gray-500 font-bold text-sm '>
          *We ensure that your personal information will be kept in utmost
          confidence and will not be shared with third parties.
        </p>
        <UserForm />
      </div>
      <Footer />
    </div>
  );
};

export default UserInformation;
