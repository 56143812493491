import React from 'react';
import logo from '../../assets/White logo - no background.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className='w-full h-[300px] sm:h-[200px] bg-[#004aac] flex flex-col px-10 rounded-t-[20px] '>
      <div className='w-full flex flex-col sm:flex-row items-center justify-between'>
        <Link
          onClick={() => scrollToTop()}
          to={'/'}
          className='max-w-[450px] h-[150px]'
        >
          <img src={logo} className='w-full h-full' alt='logo' />
        </Link>
        <div className='flex flex-col  justify-center gap-2'>
          <Link
            onClick={() => scrollToTop()}
            className='font-poppins text-lg text-white hover:underline'
            to={'/privacy-policy'}
          >
            Privacy Policy
          </Link>
          <Link
            onClick={() => scrollToTop()}
            className='font-poppins text-lg text-white hover:underline'
            to={'/terms'}
          >
            Terms of Use
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
