import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../store/hooks';
import { Button } from '../components/ui/button';
import {
  QuestionState,
  clearQuestionsAndAnswers,
  storeQuestions,
} from '../store/questionSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { ReloadIcon } from '@radix-ui/react-icons';
import { fetchQuestionsData } from '../utils/Api/FetchQuestions';
import { Header } from '../components/Header/Header';
import axios from 'axios';
import { submitResponse } from '@/types';
import { clearUser } from '../store/userSlice';
import { SkeletonCard } from '../components/Skeleton/Skeleton';
import ThankYou from '../components/ThankYouModal/ThankYou';
import Footer from '../components/Footer/Footer';
import Question from '../components/Question/Question';

const areAllQuestionsAnswered = (questions: any[], answers: any[]) => {
  return questions.every((question) =>
    answers.find((answer) => answer.questionid === question.id)
  );
};

const QuestionsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  const { page } = useParams();

  const questionData: QuestionState = useAppSelector(
    (state: any) => state.questions
  );

  const { user } = useAppSelector((state) => state.user);
  const answers = questionData.answer;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getQuestions = async () => {
    try {
      setIsLoading(true);
      if (user) {
        const response = await fetchQuestionsData(user);
        dispatch(storeQuestions(response));
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const handleSubmit = async () => {
    const ifAllAnswer =
      questionData.questions.length === questionData.answer.length;
    if (!ifAllAnswer) {
      setError('Please answer all the questions');
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        userid: user?.id,
        answers: questionData.answer,
      };

      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });

      const response: { data: submitResponse } = await axiosInstance.post(
        '/submit/',
        payload
      );

      if (response.data.status === 'SUCCESS') {
        setShowModal(true);
      } else {
        setError('Submission failed');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      setError('Submission failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    dispatch(clearUser());
    dispatch(clearQuestionsAndAnswers());
    navigate('/');
    scrollToTop();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    handleCancel();
  };

  const maxPage = Math.max(
    ...questionData.questions.map((question) => question.page)
  );

  const Questions = questionData.questions.filter(
    (question) => question.page === Number(page)
  );

  const handleNext = () => {
    const currentPageQuestions = Questions.filter(
      (question) => question.page === Number(page)
    );

    if (areAllQuestionsAnswered(currentPageQuestions, answers)) {
      const nextPage = Number(page) + 1;
      navigate(`/questions/${nextPage}`);
      scrollToTop();
    } else {
      setError(
        'Please answer all the questions before proceeding to the next page.'
      );
    }
  };

  return (
    <>
      <Header />
      <div className='w-full  flex flex-col items-center justify-center p-10  '>
        {isLoading ? (
          <SkeletonCard />
        ) : (
          <div className='w-full lg:w-3/4 flex flex-col items-center gap-4'>
            <div className='flex flex-col gap-2'>
              <p className='font-medium text-gray-500 font-poppins text-xs tracking-wide leading-6 md:text-[1rem]'>
                <span className='font-semibold'>Directions:</span> This
                questionnaire exhibit the typical ways you handled yourself and
                people. There are no right or wrong answers. Don’t tempt to
                answer questions in terms of what a person should do. This is
                not what is wanted here. We would like to know how you actually
                respond to a situation. There is no time limit, but do not
                ponder long over any item. The questionnaire consists of two
                pages, page one and page two. Please complete the first page to
                go to the second page
              </p>
              <div className='font-semibold font-poppins'>
                <p>Note:</p>
                <p className=' text-xs md:text-sm'>
                  We ensure that your personal information will be kept in
                  utmost confidence and will not be shared with third parties.
                </p>
                <p className=' text-xs md:text-sm'>
                  Based on your feelings and experiences in the recent past
                  choose from the options given for each item.
                </p>
                <p className=' text-xs md:text-sm text-red-600'>
                  * All questions are mandatory
                </p>
              </div>
            </div>
            <p className='font-poppins text-2xl font-semibold underline tracking-wider'>
              Part : {page}
            </p>

            <div className=' w-full  flex flex-col gap-5 p-5'>
              {Questions.map((question: any, index) => (
                <Question
                  key={question.id}
                  question={question}
                  answers={answers}
                  readonly={questionData.readonly}
                  length={index}
                />
              ))}
            </div>
            <div className='sm:flex-row flex flex-col  justify-between mt-10 items-center gap-6 '>
              <Button onClick={handleCancel} variant='destructive'>
                Cancel
              </Button>
              {Number(page) > 1 && (
                <Button
                  onClick={() => {
                    const prevPage = Number(page) - 1;
                    navigate(`/questions/${prevPage}`);
                    scrollToTop();
                  }}
                  variant='default'
                >
                  Previous
                </Button>
              )}

              {Number(page) < maxPage && (
                <Button onClick={handleNext} variant='default'>
                  Next
                </Button>
              )}
              {Number(page) === maxPage && !questionData.readonly && (
                <Button
                  variant='optionFull'
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <ReloadIcon className='mr-2 h-4 w-4 animate-spin' />
                    </>
                  ) : (
                    'Submit'
                  )}
                </Button>
              )}
            </div>
            {showModal && <ThankYou handleCloseModal={handleCloseModal} />}
            {error && <p className='text-red-600 font-semibold'>{error}</p>}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default QuestionsPage;
