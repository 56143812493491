import axios from 'axios';

export const fetchQuestionsData = async (user: any) => {
  const payload = {
    userid: user.id,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fetch_question/`,
      payload
    );
    const questionsData = response.data.data;

    return questionsData;
  } catch (error) {
    throw new Error('Network error');
  }
};
