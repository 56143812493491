import { Skeleton } from '../../components/ui/skeleton';

const times = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export function SkeletonCard() {
  return (
    <>
      {times.map((time) => (
        <div key={time} className='flex flex-col lg:w-3/4 w-full space-y-6 p-4'>
          <Skeleton className='h-[55px] w-full rounded-xl' />
          <div className='sm:flex-row w-full  lg:items-center  flex flex-col  items-center gap-4 justify-center sm:justify-between '>
            <Skeleton className='h-10 sm:w-[150px] w-full' />
            <Skeleton className='h-10 sm:w-[150px] w-full' />
            <Skeleton className='h-10 sm:w-[150px] w-full' />
            <Skeleton className='h-10 sm:w-[150px] w-full' />
            <Skeleton className='h-10 sm:w-[150px] w-full' />
          </div>
        </div>
      ))}
    </>
  );
}
