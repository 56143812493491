export const PrivacyTerms = [
  {
    heading: 'Information We Collect: ',
    types: [
      {
        subheading: 'Personal Information:',
        info: 'When you use our website or services, we may collect personal information such as your name, email address, and demographic information.',
      },
      {
        subheading: 'Usage Data:',
        info: 'We may also collect non-personal information about your interactions with our website, including pages visited, time spent on the site, and other usage data. ',
      },
      {
        subheading: 'Cookies:',
        info: 'We use cookies and similar technologies to enhance your browsing experience and analyze website traffic. You have the option to disable cookies in your browser settings, although this may affect certain features of the website. ',
      },
    ],
  },
  {
    heading: 'How We Use Your Information:',
    types: [
      {
        subheading: 'Personalization:',
        info: 'We use your information to personalize your experience on our website and tailor our services to better meet your needs. ',
      },
      {
        subheading: 'Communication:',
        info: 'We may use your email address to send you important updates, newsletters, and promotional offers related to mental wellness.',
      },
      {
        subheading: 'Analytics:',
        info: ' We analyze user data to improve our website, services, and user experience. ',
      },
    ],
  },
  {
    heading: 'Data Security:',
    types: [
      {
        subheading: '',
        info: 'We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, or alteration. ',
      },
      {
        subheading: '',
        info: 'However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security of your data. ',
      },
    ],
  },
  {
    heading: 'Third-Party Links:',
    types: [
      {
        subheading: '',
        info: 'Our website may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices or content of these third-party sites. ',
      },
    ],
  },
  {
    heading: 'Changes to Privacy Policy: ',
    types: [
      {
        subheading: '',
        info: 'We reserve the right to update or modify this Privacy Policy at any time. We will notify users of any changes by posting the revised policy on this page. ',
      },
    ],
  },
];
