import Footer from '../components/Footer/Footer';
import About from '../components/About/About';
import { Header } from '../components/Header/Header';
import { Hero } from '../components/Hero/Hero';

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <About />
      <Footer />
    </div>
  );
};

export default Home;
