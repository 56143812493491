import { Button } from '../components/ui/button';
import Footer from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { cn } from '../lib/utils';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router';

const Terms = () => {
  const navigate = useNavigate();
  return (
    <div className=' w-full min-h-full  font-poppins flex flex-col items-center justify-center  gap-4  '>
      <Header />
      <div className='w-full md:w-3/4 p-4 flex flex-col gap-10 mt-10'>
        <Button
          onClick={() => navigate('/')}
          className={cn('max-w-[200px]')}
          variant='optionFull'
        >
          <ArrowLeft />
          Back
        </Button>
        <div className='flex flex-col gap-6'>
          <h1 className='text-2xl font-semibold'>Terms of Use: </h1>
          <h2 className='text-xl font-medium text-gray-600'>
            By accessing the website, you agree to abide by the following terms
            and conditions:
          </h2>
        </div>
        <div className='flex flex-col gap-6'>
          <p className='text-lg text-gray-600'>
            <span className='font-medium text-black'>
              Content and Services:
            </span>
            Our website provides information, resources, and services related to
            mental wellness for educational purposes only. The content is not
            intended to replace professional advice or treatment.
          </p>
          <p className='text-lg text-gray-600'>
            <span className='font-medium text-black'>User Conduct:</span>
            You agree to use our website and services in a manner consistent
            with applicable laws and regulations. You are prohibited from
            engaging in any unlawful or harmful activities that may disrupt or
            interfere with the website's operation or the experiences of other
            users.
          </p>
          <p className='text-lg text-gray-600'>
            <span className='font-medium text-black'>
              Intellectual Property:
            </span>
            All content, logos, trademarks, and other intellectual property
            displayed on the website are owned by Eunoia or its licensors and
            are protected by copyright and other intellectual property laws. You
            may not reproduce, distribute, or modify any content without prior
            written consent.
          </p>
          <p className='text-lg text-gray-600'>
            <span className='font-medium text-black'>
              Disclaimer of Warranties:
            </span>
            Eunoia makes no warranties or representations about the accuracy,
            reliability, or suitability of the content and services provided on
            the website. Use of the website is at your own risk.
          </p>
          <p className='text-lg text-gray-600'>
            <span className='font-medium text-black'>
              Limitation of Liability:
            </span>
            Eunoia shall not be liable for any direct, indirect, incidental,
            special, or consequential damages arising out of or in connection
            with your use of the website or services.
          </p>
          <p className='text-lg text-gray-600'>
            <span className='font-medium text-black'>Governing Law:</span>
            These Terms of Use shall be governed by and construed in accordance
            with the laws of United States, without regard to its conflict of
            law provisions.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
