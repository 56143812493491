import React from 'react';
import { Button } from '../ui/button';
import { useNavigate } from 'react-router';
import heroImage from '../../assets/hero.webp';
import ellipse from '../../assets/Ellipse.svg';
import { cn } from '../../lib/utils';

export const Hero = () => {
  const navigate = useNavigate();
  return (
    <div
      id='hero'
      className='bg-white w-full min-h-screen flex flex-col sm:flex-row  relative  items-start p-6  overflow-hidden '
    >
      <div className='w-full min-h-[400px] lg:w-1/2 flex flex-col    order-2 sm:order-1  '>
        <div className='w-full h-full  flex flex-col gap-4 p-4 z-[20] '>
          <h1 className='sm:text-6xl text-4xl  font-poppins font-bold  text-[#004aac]  tracking-wider '>
            Eunoia
          </h1>
          <h2 className='font-poppins text-[#284a75] text-2xl -tracking-wider font-medium'>
            Take Our Questionnaire: Validate Your Mental Wellness Journey{' '}
          </h2>
          <p className='font-poppins text-gray-700 text-md '>
            Are you a student seeking support for your mental wellbeing? Take
            our questionnaire to help us understand your needs and how we can
            better support you on your wellness journey. Your responses will
            help us tailor our resources and services to best meet the needs of
            students like you.{' '}
          </p>
          <Button
            className={cn('max-w-[200px] self-center')}
            variant='optionFull'
            onClick={() => navigate('/user')}
          >
            Take Test
          </Button>
        </div>
      </div>
      <div className='md:w-[650px]  w-full h-[500px] order-1 sm:order-2 '>
        <img
          src={heroImage}
          className='w-full h-full object-contain'
          alt='mental-health diagram'
        />
      </div>
      <div className='bottom-[-70px] left-[-160px] sm:bottom-[-50px] sm:left-[-50px] md:left-[-60px] md:bottom-[0px] absolute rotate-90 z-[10] overflow-hidden'>
        <img
          src={ellipse}
          alt='ellipse'
          className=' w-[250px] h-[250px] object-contain'
        />
      </div>
    </div>
  );
};
