import React from 'react';
import { Button } from '../../components/ui/button';
import { useDispatch } from 'react-redux';
import { updateQuestionAnswer } from '../../store/questionSlice';

interface QuestionProps {
  question: any;
  answers: any[];
  readonly: boolean;
  length: number;
}

const Question: React.FC<QuestionProps> = ({
  question,
  answers,
  readonly,
  length,
}) => {
  const dispatch = useDispatch();

  return (
    <div className='w-full flex flex-col justify-center gap-5 p-2 '>
      <div className='sm:text-xl  text-gray-600 font-medium tracking-wider font-poppins flex gap-2 '>
        <p>{length + 1}.</p>
        <p className='  text-gray-600 '>
          {question.question}
          <span className='text-red-600'>*</span>
        </p>
      </div>

      <div className='md:flex-row w-full  flex flex-col gap-3   items-center   '>
        {question.options.map((option: any) => (
          <Button
            disabled={readonly}
            key={option.id}
            variant={
              answers &&
              answers?.find(
                (item: any) =>
                  item.questionid === question.id && item.answerid === option.id
              )
                ? 'optionFull'
                : 'optionOutline'
            }
            onClick={() => {
              dispatch(
                updateQuestionAnswer({
                  questionid: question.id,
                  answerid: option.id,
                })
              );
            }}
          >
            {option.text}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Question;
