import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../..//store/Store';
import { ReloadIcon } from '@radix-ui/react-icons';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useNavigate } from 'react-router';
import { userDataStore } from '../../store/userSlice';
import { useAppSelector } from '../../store/hooks';
import { cn } from '../../lib/utils';

const UserFormSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  age: z.coerce
    .number()
    .min(1, 'Age is required')
    .max(120, 'Age must be less than or equal to 120'),
  school_name: z.string().min(1, 'School name is required'),
  email_id: z.string().email('Please enter a valid email address'),
  phone_number: z.string().regex(/^\d{10}$/, 'Phone number must be 10 digits'),
});

type FormValues = z.infer<typeof UserFormSchema>;

const UserForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof UserFormSchema>>({
    resolver: zodResolver(UserFormSchema),
    defaultValues: {
      name: '',
      age: 0,
      school_name: '',
      email_id: '',
      phone_number: '',
    },
  });

  const { loading, error } = useAppSelector((state) => state.user);

  async function onSubmit(values: FormValues) {
    try {
      const response = await dispatch(userDataStore(values));

      if (response.payload) {
        return;
      } else {
        navigate('/questions/1');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className='space-y-4 flex flex-col '
      >
        <FormField
          control={form.control}
          name='name'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name:</FormLabel>
              <FormControl>
                <Input placeholder='Your name' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='age'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Age (years):</FormLabel>
              <FormControl>
                <Input placeholder='Your Age' type='number' {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='school_name'
          render={({ field }) => (
            <FormItem>
              <FormLabel>School Name / University Name:</FormLabel>
              <FormControl>
                <Input placeholder='Your School Name' {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='email_id'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email:</FormLabel>
              <FormControl>
                <Input placeholder='Your Email Address' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='phone_number'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone Number:</FormLabel>
              <FormControl>
                <Input placeholder='Your Phone Number' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex justify-between items-center flex-wrap gap-4 p-4 '>
          <Button variant='optionFull' type='submit' disabled={loading}>
            {loading ? (
              <>
                <ReloadIcon className='mr-2 h-4 w-4 animate-spin' />
                Please wait
              </>
            ) : (
              'Next'
            )}
          </Button>
          <Button
            className={cn('w-full')}
            disabled={loading}
            variant='destructive'
            onClick={() => navigate('/')}
          >
            Cancel
          </Button>
        </div>
        <div className='self-center h-6 '>
          {error && (
            <p className='text-red-500 font-bold self-center'>{error}</p>
          )}
        </div>
      </form>
    </Form>
  );
};

export default UserForm;
