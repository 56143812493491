import Footer from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import React from 'react';
import notFound from '../assets/404.jpg';

const FourOhFourPage = () => {
  return (
    <div className=' w-full min-h-full  font-poppins flex flex-col items-center justify-center  gap-4 '>
      <Header />
      <div className='w-full md:w-3/4 p-4 flex flex-col items-center justify-center gap-10 mt-10'>
        <div className='w-[350px]'>
          <img src={notFound} className='w-full h=full' />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FourOhFourPage;
