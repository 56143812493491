import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import UserInformation from './Pages/UserInformation';
import { useAppSelector } from './store/hooks';
import QuestionsPage from './Pages/QuestionsPage';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import FourOhFourPage from './Pages/PageNotFound';

function App() {
  const { user } = useAppSelector((state) => state.user);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route
          path='/user'
          element={user ? <Navigate to={'/questions'} /> : <UserInformation />}
        />
        <Route
          path='/questions'
          element={
            user ? <Navigate to={'/questions/1'} /> : <Navigate to={'/'} />
          }
        />
        <Route
          path='/questions/:page'
          element={user ? <QuestionsPage /> : <Navigate to={'/'} />}
        />
        <Route path='/privacy-policy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='*' element={<FourOhFourPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
