import axios from 'axios';

type User = {
  id: number;
  name: string;
  age: number;
  school_name: string;
  email_id: string;
  phone_number: string;
};

type UserResponse = {
  userid: number;
  message: string;
  status: 'SUCCESS' | 'FAILURE';
  user: User[] | null;
};
export const sendUserData = async (userData: User): Promise<UserResponse> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users_info/`,
      userData
    );

    return response.data;
  } catch (error) {
    throw new Error('Network Error');
  }
};
