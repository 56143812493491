import React from 'react';

const About = () => {
  return (
    <div id='about' className='flex flex-col md:flex-row sm:p-16 p-4  gap-4  '>
      <div className='flex flex-col gap-6 border-2 border-[#284a75] p-4 items-center py-10'>
        <h2 className='font-poppins text-[#284a75] sm:text-4xl text-2xl tracking-wider font-semibold'>
          Misson
        </h2>
        <p className='font-poppins text-gray-700 sm:text-lg text-md tracking-wide leading-8'>
          We are on a mission to change the narrative around students’ mental
          health, address their struggles through awareness, education, provide
          resources and empower them to thrive in the face of challenges. We
          envision creating a community where every student can flourish
          emotionally, academically, and socially.
        </p>
      </div>
      <div className='flex flex-col gap-4 border-2 border-[#284a75] p-4 py-10 items-center'>
        <h2 className='font-poppins text-[#284a75] sm:text-4xl text-2xl tracking-wider font-semibold'>
          Vision
        </h2>
        <p className='font-poppins text-gray-700 sm:text-lg text-md tracking-wide leading-8'>
          Our vision is to cultivate a supportive community where every
          individual thrives emotionally, academically, and socially. Through
          awareness, education, and accessible resources, we aspire to reshape
          the narrative surrounding students' mental health, fostering
          resilience and holistic well-being.
        </p>
      </div>
    </div>
  );
};

export default About;
