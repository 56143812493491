import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Answer, Question } from '@/types';

// Define the state interface for questions
export interface QuestionState {
  questions: Question[];
  loading: boolean;
  error: string | null;
  answer: Answer[];
  readonly: boolean;
}

// Define the initial state for questions
const initialState: QuestionState = {
  questions: [],
  loading: false,
  error: null,
  answer: [],
  readonly: false,
};

// Create the questions slice
export const questionSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    // Action to store questions in the state
    storeQuestions: (state, action) => {
      state.questions = action.payload.questions.sort(
        (a: any, b: any) => a.id - b.id
      );

      state.answer =
        action.payload.answers === null ? [] : action.payload.answers;
      state.readonly = action.payload.answers === null ? false : true;
    },
    // Action to update the answer for a question
    updateQuestionAnswer: (
      state,
      action: PayloadAction<{ questionid: number; answerid: number }>
    ) => {
      const { questionid, answerid } = action.payload;
      // Check if the question already has an answer
      const existingAnswerIndex = state.answer.findIndex(
        (question) => question.questionid === questionid
      );
      if (existingAnswerIndex !== -1) {
        // If the question already has an answer, update it
        state.answer[existingAnswerIndex].answerid = answerid;
      } else {
        // If the question does not have an answer, add it to the state
        state.answer.push(action.payload);
      }
    },
    // Action to clear questions and answers from the state
    clearQuestionsAndAnswers: (state) => {
      state.questions = [];
      state.loading = false;
      state.error = null;
      state.answer = [];
    },
  },
});

// Export the actions for questions
export const {
  storeQuestions,
  updateQuestionAnswer,
  clearQuestionsAndAnswers,
} = questionSlice.actions;

export default questionSlice.reducer;
