import { CheckCircle } from 'lucide-react';
import React from 'react';
import { Button } from '../ui/button';

type thankYouProps = {
  handleCloseModal: () => void;
};

const ThankYou = ({ handleCloseModal }: thankYouProps) => {
  return (
    <div>
      <div className='fixed top-0 left-0 z-50 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center p-2'>
        <div className='bg-white md:w-[400px] max-w-full h-[200px] p-8 rounded-lg shadow-lg flex flex-col items-center justify-center'>
          <p className='text-md font-poppins flex items-center gap-4 text-gray-800 mb-4 font-semibold'>
            <CheckCircle size={50} color='blue' />
            <span>Thank you for your submission!</span>
          </p>
          <Button variant='optionFull' onClick={handleCloseModal}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
