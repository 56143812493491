import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { sendUserData } from '../utils/Api/SendUserData';
import { User } from '../types';
import { RootState } from './Store';

export interface UserState {
  user: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
};

// Create an async thunk to send user data and get questions
export const userDataStore = createAsyncThunk(
  'user/sendUserData',
  async (userData: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendUserData(userData);
      if (
        response.status === 'SUCCESS' &&
        response.message === 'User created successfully.'
      ) {
        const id = response.userid;
        const updatedData = { ...userData, id };
        return updatedData;
      } else if (
        response.status === 'SUCCESS' &&
        response.message === 'User Already Exist'
      ) {
        const id = response.userid;
        const updatedData = { ...userData, id };
        return updatedData;
      } else {
        throw new Error('Failed to send user data');
      }
    } catch (error) {
      let errorMessage = 'Network Error';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage as string);
    }
  }
);

// Create the user slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Clear user data
    clearUser: (state) => {
      return {
        ...state,
        user: null,
        loading: false,
        error: null,
      };
    },
  },
  // Handle the async thunk actions
  extraReducers: (builder) => {
    builder.addCase(userDataStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      userDataStore.fulfilled,
      (state, action: PayloadAction<User>) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      }
    );
    builder.addCase(userDataStore.rejected, (state, action) => {
      state.loading = false;

      state.error = action.payload as string;
    });
  },
});

// Select the user data from the state
export const selectUser = (state: RootState) => state.user.user;

// Export the clear user action
export const { clearUser } = userSlice.actions;

export default userSlice.reducer;
