import React from 'react';
import logo from '../../assets/Color logo - no background.svg';
import { Link } from 'react-router-dom';

export const Header = () => {
  return (
    <header className='   w-screen  md:w-full top-0 left-0 '>
      <div className=' bg-white py-2 w-full flex flex-col items-center justify-center md:px-10 px-7 shadow-sm md:items-start'>
        <Link to='/'>
          <img src={logo} className='w-[500px] h-full' alt='logo' />
        </Link>
      </div>
    </header>
  );
};
