import React from 'react';
import { PrivacyTerms } from '../utils/Constants/Privacy';
import Footer from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { Button } from '../components/ui/button';
import { cn } from '../lib/utils';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router';

const Privacy = () => {
  const navigate = useNavigate();
  return (
    <div className=' w-full min-h-full  font-poppins flex flex-col items-center justify-center  gap-4 '>
      <Header />
      <div className='w-full md:w-3/4 p-4 flex flex-col gap-10 mt-10'>
        <Button
          onClick={() => navigate('/')}
          className={cn('max-w-[200px]')}
          variant='optionFull'
        >
          <ArrowLeft />
          Back
        </Button>
        <div className='flex flex-col gap-4'>
          <h1 className='text-2xl font-semibold'>Privacy Policy:</h1>
          <h2 className='text-xl font-medium text-gray-600'>
            At Eunoia, we are committed to safeguarding the privacy of our users
            while providing a secure and supportive environment for mental
            wellness. This Privacy Policy outlines how we collect, use, and
            protect your personal information when you visit our website and use
            our services.
          </h2>
        </div>
        <div className='flex flex-col gap-6'>
          {PrivacyTerms.map((privacy) => (
            <div className='flex flex-col gap-2 p-2'>
              <h2 className='text-lg font-medium underline'>
                {privacy.heading}
              </h2>
              {privacy.types.map((type) => (
                <p className='text-lg text-gray-600'>
                  <span className='font-medium text-black'>
                    {type.subheading}
                  </span>
                  {type.info}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
